import React from "react"

import Container from 'react-bootstrap/Container'

import "./footer.scss"

const Footer = (props) => (

	<div className="ftrs-footer">
		<Container>
			<div className="ftrs-footer__part1" data-sal="fade" data-sal-duration="1000">
				{
					props.lang === 'cs' ? "Připraveni na spolupráci?" 
					: props.lang === 'de' ? "Bereit für\u00A0eine\u00A0Zusammenarbeit?" 
					: "Ready to work with us?"
				}
			</div>
			<div className="ftrs-footer__part2" data-sal="fade" data-sal-duration="1000">
				{
					props.lang === 'cs' ? "Praha, Česká republika" 
					: props.lang === 'de' ? "Prag, Tschechien" 
					: "Prague, Czech Republic"
				}
			</div>
			<div>
				<a href="mailto:hello@futurerockstars.cz" className="ftrs-footer__email" data-sal="fade" data-sal-duration="1000">hello@futurerockstars.cz</a>
			</div>
			<div>
				<a href="tel:+420721765529" className="ftrs-footer__phone" data-sal="fade" data-sal-duration="1000">+420 721 765 529</a>
			</div>

		</Container>
	</div>

)

export default Footer